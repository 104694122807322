<template>
  <div class='border-t hidden lg:block'>
    <div class='layout-container w-full px-4 lg:px-0 py-12 bg-white'>
      <div class='grid grid-cols-2 gap-8 lg:flex lg:flex-row lg:justify-between lg:items-stretch'>
        <div class='flex flex-col justify-end items-start'>
          <span class='uppercase text-xs font-semibold'>Hosted by</span>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/logos/host_logo.png'
               class='flex-grow-0 flex-shrink-0 h-10 lg:h-12 cursor-pointer'
               @click="goToSite('host')"/>
        </div>
        <div class='flex flex-col justify-end items-start'>
          <span class='uppercase text-xs font-semibold'>Organized by</span>
          <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2024/org_by_logo.png'
               class='flex-grow-0 flex-shrink-0 h-10 lg:h-12 cursor-pointer'
               @click="goToSite('organizing')"/>
        </div>
        <div class='flex flex-col justify-end items-start'>
          <span class='uppercase text-xs font-semibold'>Partner with</span>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/intercom_partners.png'
               class='flex-grow-0 flex-shrink-0 h-10 lg:h-12 cursor-pointer pt-1'
               @click="goToSite('partner')"/>
        </div>
        <div class='flex flex-col justify-end items-start'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/logos/k-convention_logo.png'
               class='flex-grow-0 flex-shrink-0 h-10 lg:h-12' />
        </div>
        <div class='flex flex-col justify-end items-start'>
          <span class='uppercase text-xs font-semibold'>Partially Sponsored by</span>
          <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2024/kyowa_kirin_logo.png'
               class='flex-grow-0 flex-shrink-0 h-10 lg:h-12' />
        </div>
      </div>
      <div class='flex flex-col lg:flex-row justify-start gap-y-16 text-xs lg:text-sm text-gray-700 mt-16'>
        <div class='w-full lg:w-1/2'>
          <div class='font-semibold text-sm lg:text-base'>Global Breast Cancer Conference Secretariat <br>Partner I INTERCOM Convention Services, Inc. (Core PCO)</div>
          <div class='mt-4 text-sm flex flex-row justify-start'>
            <div class='uppercase inline-block opacity-70 w-36 flex-shrink-0'>Contact Number</div>
            <div>+82-2-3452-7291</div>
          </div>
          <div class='mt-2 text-sm flex flex-row justify-start'>
            <div class='uppercase inline-block opacity-70 w-36 flex-shrink-0'>Email</div> 
            <div><a href='mailto:gbcc@intercom.co.kr'>gbcc@intercom.co.kr</a></div>
          </div>
          <div class='mt-2 text-sm flex flex-row justify-start'>
            <div class='uppercase inline-block opacity-70 w-36 flex-shrink-0'>Fax</div> 
            <div>+82-2-565-2434</div>
          </div>
          <div class='mt-2 text-sm flex flex-row justify-start'>
            <div class='uppercase inline-block opacity-70 w-36 flex-shrink-0'>Address</div> 
            <div>6F, squareME Bldg., 225 Bongeunsa-ro, Gangnam-gu, Seoul 06109, Korea</div>
          </div>
          <div class='mt-2 text-sm flex flex-row justify-start'>
            <div class='uppercase inline-block opacity-70 w-36 flex-shrink-0'>Website</div> 
            <div><a href='https://www.gbcc.kr' target='_blank'>www.gbcc.kr</a></div>
          </div>
        </div>
        <div class='w-full lg:w-1/2 flex flex-row justify-center items-center'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/simpl_footer_image.png'
          class='h-10 lg:h-12' />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Gbcc2024LayoutFooter',
  data () {
    return {
      publicPath: process.env.VUE_APP_URL,
    }
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    isGbccOnsitePublicSession () {
      return window.location.pathname.includes('public-session')
    },
  },
  methods: {
    ...mapActions('users',[
      'checkTokenStatus',
    ]),
    goToSite (site) {
      let siteUrl = ''
      if (site === 'host') {
        siteUrl = 'https://www.kbcs.or.kr/'
      } else if (site == 'organizing') {
        siteUrl = 'https://gbcc.kr/main.asp'
      } else if (site === 'support') {
        siteUrl = 'https://www.kbcf.or.kr'
      } else if (site === 'partner') {
        siteUrl = 'http://www.intercompco.co.kr/'
      }
      window.open(siteUrl, '_blank')
    },
  },
  created () {
    if (!this.isGbccOnsitePublicSession) {
      this.checkTokenStatus().then(() => {
        if (!this.hasValidToken) {
          this.$router.replace({name: 'Login'}).catch(() => {})
        }
      })
    }
  }
}
</script>
